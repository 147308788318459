class KeyCodeHelper {
  keys = {
    KEY_BACKSPACE: 8,
    KEY_TAB: 9,
    KEY_ENTER: 13,
    KEY_ESCAPE: 27,
    KEY_SPACE: 32,
    KEY_HOME: 35,
    KEY_END: 36,
    KEY_LEFT: 37,
    KEY_UP: 38,
    KEY_RIGHT: 39,
    KEY_DOWN: 40,
    KEY_INSERT: 45,
    KEY_DELETE: 46,
  };
  keyIntervals = {
    numbers: {
      from: 48,
      to: 57,
    },
    numericKeypad: {
      from: 96,
      to: 105,
    },
  };

  isGenericEditorKeyCode(event) {
    if (event.shiftKey || event.altKey || event.ctrlKey) {
      return false;
    }

    const key = event.which || event.keyCode;

    switch (key) {
      case this.keys.KEY_BACKSPACE:
      case this.keys.KEY_TAB:
      case this.keys.KEY_ENTER:
      case this.keys.KEY_HOME:
      case this.keys.KEY_END:
      case this.keys.KEY_LEFT:
      case this.keys.KEY_RIGHT:
      case this.keys.KEY_DELETE:
      case this.keys.KEY_INSERT:
        return true;
      default:
        return false;
    }
  }

  isNumericKeyCode(event) {
    if (event.shiftKey || event.altKey || event.ctrlKey) {
      return false;
    }

    const key = event.which || event.keyCode;

    if (
      (key >= this.keyIntervals.numbers.from && key <= this.keyIntervals.numbers.to) ||
      (key >= this.keyIntervals.numericKeypad.from && key <= this.keyIntervals.numericKeypad.to) ||
      this.isGenericEditorKeyCode(event)
    ) {
      return true;
    }

    return false;
  }
}

const helper = new KeyCodeHelper();
export default helper;
