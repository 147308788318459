class EventAggregator {
  constructor() {
    this.events = {};
    this.hOP = this.events.hasOwnProperty;
  }

  subscribe(eventName, callbackFunction) {
    // Create the eventName's object if not yet created
    if (!this.hOP.call(this.events, eventName)) {
      this.events[eventName] = [];
    }

    // Add the listener to queue
    var index = this.events[eventName].push(callbackFunction) - 1;

    // Provide handle back for removal of eventName
    return {
      remove: () => {
        delete this.events[eventName][index];
      },
    };
  }

  publish(eventName, info) {
    // If the eventName doesn't exist, or there's no listeners in queue, just leave
    if (!this.hOP.call(this.events, eventName)) {
      return;
    }

    // Cycle through events queue, fire!
    this.events[eventName].forEach(function (item) {
      item(info !== undefined ? info : {});
    });
  }
}

const singleton = new EventAggregator();
export default singleton;
