import { createDate } from "./date-helper";

class SessionStorageHelper {
  isSessionStorageAvailable() {
    try {
      sessionStorage.isAvailable = true;
    } catch (e) {
      return false;
    }

    const isBrowser = typeof window !== "undefined";
    return isBrowser;
  }

  setItem(key, value, timestamp) {
    if (!this.isSessionStorageAvailable()) {
      return;
    }

    if (!key || !value) {
      return;
    }

    if (timestamp) {
      value = { value: value, sessionStorageItemValidUntil: timestamp };
    }

    if (typeof value === "object") {
      value = JSON.stringify(value);
    }

    sessionStorage.setItem(key, value);
  }

  isTimeStampValid(timestamp) {
    const now = createDate();
    return timestamp >= now.setMinutes(now.getMinutes());
  }

  getItem(key) {
    if (!this.isSessionStorageAvailable()) {
      return undefined;
    }
    if (typeof sessionStorage === "undefined") {
      return null;
    }

    let value = sessionStorage.getItem(key);
    if (!value) {
      return;
    }
    if (value[0] === "{" || value[0] === "[") {
      value = JSON.parse(value);
    }

    if (value && !value.sessionStorageItemValidUntil) {
      return value;
    }

    return this.valueTimeStampValidation(value, key);
  }

  valueTimeStampValidation(value, key) {
    if (this.isTimeStampValid(value.sessionStorageItemValidUntil)) {
      return value.value;
    } else {
      this.removeItem(key);
      return undefined;
    }
  }

  removeItem(key) {
    if (!this.isSessionStorageAvailable()) {
      return;
    }
    sessionStorage.removeItem(key);
  }
}

const helper = new SessionStorageHelper();
export default helper;
