import DateHelper, { createDate } from "./date-helper";
import dayjs from "dayjs";
import { every } from "lodash-es";
import { corei18n } from "@/core/labels/i18n";

export function validateEmail(email) {
  return /^([\w+-]+\.)*[\w+-]+@([\w+-]+\.)*[\w+-]+\.[a-zA-Z]{2,}$/.test(email);
}

export function validatePhone(phone) {
  return /^[+]?(\d){0,2}?([\d\-\s]){10,15}$/.test(phone);
}

export function validateZipNo(zipNo) {
  return /^[0-9]{3}[ ]?[0-9]{1,2}$/.test(zipNo);
}

export function validateLicensePlate(licensePlate) {
  // eslint-disable-next-line no-useless-escape
  return /^[A-Za-z0-9\å\Å]{2,7}$/.test(licensePlate);
}

export function isValidBirthYear(birthYear) {
  const birthYearAsNumber = parseInt(birthYear);
  const currentYear = createDate().getFullYear();
  return birthYearAsNumber > 1900 && birthYearAsNumber < currentYear;
}

export function isValidMonth(month) {
  const monthAsNumber = parseInt(month);
  return monthAsNumber >= 1 && monthAsNumber <= 12;
}

export function isValidDayInGenericMonth(day) {
  const dayAsNumber = parseInt(day);
  return dayAsNumber >= 1 && dayAsNumber <= 31;
}

export function validateUser(user) {
  const invalid = [];
  const valid = every(user, (value, key) => {
    if (key === "requiresContact") {
      // Ignore this field
      return true;
    }

    let valid = false;
    let message = "";
    if (key === "email") {
      valid = validateEmail(value);
      message = corei18n.t("invalidEmail");
    } else if (key === "birthDate") {
      valid = validateBirthDate(value, 1, 120);
      message = corei18n.t("invalidDateOfBirth");
    } else if (key === "phoneNo") {
      valid = validatePhone(value);
      message = corei18n.t("invalidPhone");
    } else if (key === "postalCode") {
      valid = validateZipNo(value);
      message = corei18n.t("invalidPostalCode");
    } else if (key === "contactFirstName" || key === "contactLastName") {
      valid = user.requiresContact ? !!value : true;
      message = corei18n.t("inputValidationNoValue");
    } else if (key === "contactPhone") {
      valid = user.requiresContact ? validatePhone(value) : true;
      message = corei18n.t("invalidPhone");
    } else {
      valid = !!value;
      message = corei18n.t("inputValidationNoValue");
    }

    if (!valid) {
      invalid.push({ key: key, message: message });
    }

    return valid;
  });

  return { valid: valid, errors: invalid };
}

export function validateBirthDate(birthDate, minAgeInYears, maxAgeInYears) {
  if (!birthDate) {
    return false;
  }

  if (birthDate.length !== 10) {
    return false;
  }

  const minAgeValid = validateMinBirthDate(birthDate, minAgeInYears);
  if (!minAgeValid) {
    return false;
  }

  const maxAgeValid = validateMaxBirthDate(birthDate, maxAgeInYears);
  if (!maxAgeValid) {
    return false;
  }

  return checkIfDateIsValid(birthDate);
}

export const isValidDayInMonth = (year, month, day) => {
  if (!isValidBirthYear(year)) {
    return false;
  }

  if (!isValidMonth(month)) {
    return false;
  }

  if (!isValidDayInGenericMonth(day)) {
    return false;
  }

  const yearAndMonth = dayjs(`${year}-${month}`).format("YYYY-MM");
  const daysInMonth = dayjs(yearAndMonth).daysInMonth();
  const dayAsNumber = parseInt(day);

  return dayAsNumber <= daysInMonth;
};

export const checkIfDateIsValid = (dateString) => {
  if (!dateString) {
    return false;
  }

  if (!dayjs(dateString).isValid()) {
    return false;
  }

  const splittedDate = dateString.split("-");
  const year = splittedDate[0];
  const month = splittedDate[1];
  const day = splittedDate[2];

  if (!isValidMonth(month)) {
    return false;
  }

  return isValidDayInMonth(year, month, day);
};

export function validateMinBirthDate(birthDate, minAgeInYears) {
  if (!birthDate) {
    return false;
  }
  const birthDateAsDate = createDate(birthDate);
  if (!DateHelper.isDateValid(birthDateAsDate)) {
    return false;
  }
  const minAgeYearsAgo = createDate();
  minAgeYearsAgo.setFullYear(minAgeYearsAgo.getFullYear() - minAgeInYears);

  return birthDateAsDate < minAgeYearsAgo;
}

export function validateMaxBirthDate(birthDate, maxAgeInYears) {
  if (!birthDate) {
    return false;
  }
  const birthDateAsDate = createDate(birthDate);
  if (!DateHelper.isDateValid(birthDateAsDate)) {
    return false;
  }
  const maxAgeYearsAgo = createDate();
  maxAgeYearsAgo.setFullYear(maxAgeYearsAgo.getFullYear() - maxAgeInYears);

  return birthDateAsDate > maxAgeYearsAgo;
}
