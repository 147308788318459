class StringHelper {
  /**
   * @param {String} string
   * @param {number} maxCharacters
   * @returns {String} Shortened string with ... if it's larger than maxCharacters
   */
  truncateWithDots(string, maxCharacters = 80) {
    if (string.length > maxCharacters) return string.substring(0, maxCharacters) + "...";
    else return string;
  }
}

const helper = new StringHelper();
export default helper;
