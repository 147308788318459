module.exports = {
  title: "Gröna Lund",
  author: "Rebel and Bird",
  description: "Gröna Lund",
  siteUrl: process.env.GATSBY_SITE_URL ? process.env.GATSBY_SITE_URL : "https://www.gronalund.com",
  locales: [
    { locale: "sv", default: true },
    { locale: "en", default: false },
  ],
  timeZone: "Europe/Stockholm",
  timeUtcOffsetSummerTime: "+02:00",
  timeUtcOffsetNormalTime: "+01:00",
  address: {
    street: "Lilla Allmänna Gränd 9",
    city: "Stockholm",
    country: "Sweden",
    postalCode: "11521",
  },
};
