import SiteMetaData from "../../../site-metadata";
import ImageFinderHelper from "components/helpers/image-finder-helper.js";
import { LanguageHelper } from "@parksandresorts/core";

class FaceBookHelper {
  getFacebookImageByCurrentBlocks(blocks, siteConfiguration) {
    const url = ImageFinderHelper.getImageFromBlocks(blocks, siteConfiguration);

    return this.extendContentfulUrlWithHttps(url);
  }

  extendContentfulUrlWithHttps(url) {
    return /^(\/\/)/.test(url) ? "https:" + url : url;
  }

  getFacebookLocale() {
    const locale = LanguageHelper.getCurrentLocale();
    let facebookLocale = "";

    switch (locale) {
      case "sv":
        facebookLocale = "sv_SE";
        break;
      case "en":
        facebookLocale = "en_US";
        break;
      default:
        facebookLocale = "sv_SE";
    }

    return facebookLocale;
  }

  getFaceBookUrl(pageUrl) {
    return SiteMetaData.siteUrl + pageUrl;
  }
}

const helper = new FaceBookHelper();
export default helper;
