import { extract } from "@parksandresorts/core";

const URL_PARAMS = {
  STANDARD_CARD_LOW_RES_WEBP: `?w=310&q=70&fm=webp`,
  STANDARD_CARD_HIGH_RES_WEBP: `?w=600&q=70&fm=webp 2x`,
  STANDARD_CARD_LOW_RES: `?w=310&q=70`,
  STANDARD_CARD_HIGH_RES: `?w=600&q=70 2x`,
  STANDARD_CARD_FALLBACK: `?w=310&q=70`,

  MEDIA_GALLERY_WEBP: `?w=800&q=70&fm=webp`,
  MEDIA_GALLERY: `?w=800&q=70`,
  MEDIA_GALLERY_FALLBACK: `?w=500&q=70`,

  HERO_SMALL_DEVICE_LOW_RES_WEBP: `?w=500&q=70&fm=webp`,
  HERO_SMALL_DEVICE_HIGH_RES_WEBP: `?w=700&q=70&fm=webp 2x`,
  HERO_SMALL_DEVICE_LOW_RES: `?w=500&q=70`,
  HERO_SMALL_DEVICE_HIGH_RES: `?w=700&q=70 2x`,
  HERO_MEDIUM_DEVICE_LOW_RES_WEBP: `?w=900&q=70&fm=webp`,
  HERO_MEDIUM_DEVICE_HIGH_RES_WEBP: `?w=1800&q=70&fm=webp 2x`,
  HERO_MEDIUM_DEVICE_LOW_RES: `?w=900&q=70`,
  HERO_MEDIUM_DEVICE_HIGH_RES: `?w=1800&q=70 2x`,
  HERO_LARGE_DEVICE_LOW_RES_WEBP: `?w=1401&q=70&fm=webp`,
  HERO_LARGE_DEVICE_HIGH_RES_WEBP: `?w=2880&q=70&fm=webp 2x`,
  HERO_LARGE_DEVICE_LOW_RES: `?w=1401&q=70`,
  HERO_LARGE_DEVICE_HIGH_RES: `?w=2880&q=70 2x`,
  HERO_EXTRA_LARGE_DEVICE_LOW_RES_WEBP: `?w=1801&q=70&fm=webp`,
  HERO_EXTRA_LARGE_DEVICE_HIGH_RES_WEBP: `?w=3600&q=70&fm=webp 2x`,
  HERO_EXTRA_LARGE_DEVICE_LOW_RES: `?w=1801&q=70`,
  HERO_EXTRA_LARGE_DEVICE_HIGH_RES: `?w=3600&q=70 2x`,
  HERO_FALLBACK: `?w=1402&q=70`,
};

const getStandardCardPicture = (image, id) => {
  const fileUrl = extract(image, "file.url", "");

  return {
    id: id,
    src: `${fileUrl}${URL_PARAMS.STANDARD_CARD_FALLBACK}`,
    alt: extract(image, "description", ""),
    loading: "lazy",
    decode: "async",
    width: extract(image, "file.details.image.width", undefined),
    height: extract(image, "file.details.image.height", undefined),
    sources: [
      {
        srcset: `${fileUrl}${URL_PARAMS.STANDARD_CARD_LOW_RES_WEBP}, ${fileUrl}${URL_PARAMS.STANDARD_CARD_HIGH_RES_WEBP}`,
        type: "image/webp",
      },
      {
        srcset: `${fileUrl}${URL_PARAMS.STANDARD_CARD_LOW_RES}, ${fileUrl}${URL_PARAMS.STANDARD_CARD_HIGH_RES}`,
      },
    ],
  };
};

const getHeroPicture = (smallDevice, largeDevice, id) => {
  if (!smallDevice) {
    smallDevice = largeDevice;
  }

  const largeDeviceUrl = extract(largeDevice, "file.url", "");
  const smallDeviceUrl = extract(smallDevice, "file.url", "");

  return {
    id: id,
    src: `${largeDeviceUrl}${URL_PARAMS.HERO_FALLBACK}`,
    loading: "eager",
    decode: "sync",
    alt: extract(largeDevice, "description", ""),
    width: extract(largeDevice, "file.details.image.width", undefined),
    height: extract(largeDevice, "file.details.image.height", undefined),
    sources: [
      {
        srcset: `${smallDeviceUrl}${URL_PARAMS.HERO_SMALL_DEVICE_LOW_RES_WEBP}, ${smallDeviceUrl}${URL_PARAMS.HERO_SMALL_DEVICE_HIGH_RES_WEBP}`,
        media: `(max-width: 767px)`,
        type: "image/webp",
      },
      {
        srcset: `${smallDeviceUrl}${URL_PARAMS.HERO_SMALL_DEVICE_LOW_RES}, ${smallDeviceUrl}${URL_PARAMS.HERO_SMALL_DEVICE_HIGH_RES}`,
        media: `(max-width: 767px)`,
      },
      {
        srcset: `${largeDeviceUrl}${URL_PARAMS.HERO_EXTRA_LARGE_DEVICE_LOW_RES_WEBP}, ${largeDeviceUrl}${URL_PARAMS.HERO_EXTRA_LARGE_DEVICE_HIGH_RES_WEBP}`,
        media: `(min-width: 1440px)`,
        type: "image/webp",
      },
      {
        srcset: `${largeDeviceUrl}${URL_PARAMS.HERO_EXTRA_LARGE_DEVICE_LOW_RES}, ${largeDeviceUrl}${URL_PARAMS.HERO_EXTRA_LARGE_DEVICE_HIGH_RES}`,
        media: `(min-width: 1440px)`,
      },
      {
        srcset: `${largeDeviceUrl}${URL_PARAMS.HERO_LARGE_DEVICE_LOW_RES_WEBP}, ${largeDeviceUrl}${URL_PARAMS.HERO_LARGE_DEVICE_HIGH_RES_WEBP}`,
        media: `(min-width: 1200px)`,
        type: "image/webp",
      },
      {
        srcset: `${largeDeviceUrl}${URL_PARAMS.HERO_LARGE_DEVICE_LOW_RES}, ${largeDeviceUrl}${URL_PARAMS.HERO_LARGE_DEVICE_HIGH_RES}`,
        media: `(min-width: 1200px)`,
      },
      {
        srcset: `${largeDeviceUrl}${URL_PARAMS.HERO_MEDIUM_DEVICE_LOW_RES_WEBP}, ${largeDeviceUrl}${URL_PARAMS.HERO_MEDIUM_DEVICE_HIGH_RES_WEBP}`,
        media: `(min-width: 768px)`,
        type: "image/webp",
      },
      {
        srcset: `${largeDeviceUrl}${URL_PARAMS.HERO_MEDIUM_DEVICE_LOW_RES}, ${largeDeviceUrl}${URL_PARAMS.HERO_MEDIUM_DEVICE_HIGH_RES}`,
        media: `(min-width: 768px)`,
      },
    ],
  };
};

export { getStandardCardPicture, getHeroPicture };
