class DomManipulationHelper {
  constructor() {
    this.isToDisplayBlock = true;
    this.scrollTop = null;
  }

  showMain(isToRepositionToLastScrollY = false) {
    if (typeof window !== "undefined" && this.isToDisplayBlock !== true) {
      this.toggleMain(true);

      if (isToRepositionToLastScrollY) {
        this.repositionToLastScrollY();
      }
    }
  }

  hideMain() {
    if (typeof window !== "undefined" && this.isToDisplayBlock !== false) {
      this.scrollTop = window.scrollY || window.pageYOffset || document.documentElement.scrollTop;
      this.toggleMain(false);
    }
  }

  toggleMain(toState) {
    this.isToDisplayBlock = toState;
    this.isToDisplayBlock
      ? document.body.parentNode.classList.remove("html_only-show-navigation")
      : document.body.parentNode.classList.add("html_only-show-navigation");
  }

  repositionToLastScrollY() {
    if (typeof window !== "undefined" && this.scrollTop >= 0) {
      document.body.scrollTop = this.scrollTop;
      document.body.parentNode.scrollTop = this.scrollTop;
    }
  }
}

const singleton = new DomManipulationHelper();
export default singleton;
