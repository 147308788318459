import { useContext, Component, createContext } from "react";
import ThemeHelper from "components/helpers/theme-helper.js";
import { NotificationCenter, NotificationContextConsumer, NotificationContextProvider } from "@parksandresorts/core";

const GlobalContext = createContext({
  isMainVisible: true,
  isViewportScrollable: true,
  theme: "",
  overlay: {
    activeOverlays: [],
    first: {
      isOpen: false,
      props: {
        data: null,
        location: null,
      },
      animateCloseOverlay: false,
    },
    second: {
      isOpen: false,
      props: {
        data: null,
        location: null,
      },
      animateCloseOverlay: false,
    },
  },
  setValue: function (value, callback) {},
  closeActiveOverlay: function () {},
  isTopNavigationHidden: false,
  isFooterHidden: false,
  showMenu: false,
  showCart: false,
  showSearch: false,
  isAnimating: false,
  isAnimationDone: false,
  showInformationModal: false,
  animateCloseInformationModal: false,
  informationModalContent: { headline: null, productName: null, body: null },
  showUpsellModal: false,
  showJetPassModal: false,
});
const GlobalContextConsumer = GlobalContext.Consumer;

class GlobalContextProvider extends Component {
  constructor(props) {
    super(props);

    this.state = {
      theme: ThemeHelper.getTheme(),
      isMainVisible: true,
      isViewportScrollable: true,
      setValue: this.setValue.bind(this),
      closeActiveOverlay: this.closeActiveOverlay.bind(this),
      isTopNavigationHidden: false,
      isFooterHidden: false,
      showMenu: false,
      showCart: false,
      showSearch: false,
      isAnimating: false,
      isAnimationDone: false,
      showInformationModal: false,
      animateCloseInformationModal: false,
      informationModalContent: { headline: null, productName: null, body: null },
      showUpsellModal: false,
      showJetPassModal: false,

      overlay: {
        activeOverlays: [],
        first: {
          isOpen: false,
          props: {
            data: null,
            location: null,
          },
          animateCloseOverlay: false,
        },
        second: {
          isOpen: false,
          props: {
            data: null,
            location: null,
          },
          animateCloseOverlay: false,
        },
      },
    };
  }

  closeActiveOverlay() {
    const activeOverlay =
      this.state.overlay.activeOverlays instanceof Array && this.state.overlay.activeOverlays.length > 0
        ? this.state.overlay.activeOverlays[0]
        : null;

    if (activeOverlay) {
      this.setState({
        overlay: {
          ...this.state.overlay,
          [activeOverlay]: {
            ...this.state.overlay[activeOverlay],
            animateCloseOverlay: true,
          },
        },
      });
    }
  }

  setValue(value, callback) {
    if (value instanceof Object && value.hasOwnProperty("isMainVisible") && value.isMainVisible === false) {
      this.lastScrollTopPosition = window.scrollY || window.pageYOffset || document.documentElement.scrollTop;
      this.updateScrollTopOnShowMain = true;
    }

    this.setState(value, () => {
      if (this.updateScrollTopOnShowMain && this.state.isMainVisible) {
        this.updateScrollTopOnShowMain = false;
        document.body.scrollTop = this.lastScrollTopPosition;
        document.body.parentNode.scrollTop = this.lastScrollTopPosition;
      }

      if (value instanceof Object && value.hasOwnProperty("isMainVisible") && value.isMainVisible === false) {
        document.body.scrollTop = 0;
        document.body.parentNode.scrollTop = 0;
      }

      if (typeof callback === "function") {
        callback();
      }
    });
  }

  render() {
    return (
      <GlobalContext.Provider value={this.state}>
        <div id="root" className="site-theme-gronalund foundation common component body-m gronalund shared">
          <NotificationContextProvider>
            <NotificationContextConsumer>
              {(state) => (
                <NotificationCenter
                  removeNotification={state.removeNotification}
                  notifications={state.notifications}
                  setValue={this.state.setValue}
                />
              )}
            </NotificationContextConsumer>
          </NotificationContextProvider>
          {this.props.children}
        </div>
      </GlobalContext.Provider>
    );
  }
}

function useGlobalState() {
  const context = useContext(GlobalContext);
  if (context === undefined) {
    throw new Error("useGlobalState must be used within a GlobalContextProvider");
  }
  return context;
}

export default GlobalContext;
export { GlobalContext, GlobalContextConsumer, GlobalContextProvider, useGlobalState };
