class ProductCalendarHelper {
  filterProductCalendar(productCalendar, removeSoldOutProducts = true) {
    if (!(productCalendar instanceof Array && productCalendar.length > 0)) {
      return [];
    }

    const filteredProductCalendar = [];

    for (const date of productCalendar) {
      let filteredProducts = [];
      if (date.products instanceof Array && date.products.length > 0) {
        filteredProducts = date.products.filter((prod) =>
          this.isValidProductCalendarProduct(prod, removeSoldOutProducts),
        );
      }

      filteredProductCalendar.push({ date: date.date, products: filteredProducts });
    }

    return filteredProductCalendar;
  }

  isValidProductCalendarProduct(product, removeSoldOutProducts) {
    if (!product) {
      return false;
    }

    if (product.productType === "BookableProduct") {
      if (!removeSoldOutProducts) {
        return product.timeSlots instanceof Array;
      }

      const timeSlot =
        product.timeSlots instanceof Array && product.timeSlots.length > 0 ? product.timeSlots[0] : undefined;

      return timeSlot && timeSlot.quantity > 0;
    }

    return true;
  }
}

const singelton = new ProductCalendarHelper();
export default singelton;
