import TagManager from "components/events/tag-manager";

class EventHub {
  trackEvent(data) {
    TagManager.registerEvent(data);
  }

  trackEcommerce(data) {
    TagManager.registerEcommerce(data);
  }

  trackPageview(data) {
    TagManager.registerPageview(data);
  }
}

const eventHub = new EventHub();
export default eventHub;
