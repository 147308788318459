class ImageTypeSupportHelper {
  constructor() {
    this.isSupportingWebp = false;

    if (typeof window !== "undefined") {
      this.isSupportingWebp = this.testWebP();
    }
  }

  testWebP() {
    const webP = new Image();
    webP.src =
      "data:image/webp;base64,UklGRjoAAABXRUJQVlA4IC4AAACyAgCdASoCAAIALmk0mk0iIiIiIgBoSygABc6WWgAA/veff/0PP8bA//LwYAAA";
    webP.onload = webP.onerror = () => (this.isSupportingWebp = webP.height === 2);
  }
}

const singleton = new ImageTypeSupportHelper();
export default singleton;
