import LocationHelper from "components/helpers/location-helper";
import { extract, EventAggregator } from "@parksandresorts/core";

const FIRST = "first";
const SECOND = "second";

const openOverlay = (to, locationState, globalContext) => {
  if (!to || !globalContext) {
    return;
  }

  const fetchPageData = (path) => {
    if (typeof window === "undefined" || !window.___loader) {
      return Promise.reject("no go");
    }

    return window.___loader.loadPage(path).catch((err) => {
      EventAggregator.publish("trackException", {
        customMessage: "___loader.loadPage failed",
        exception: err,
      });
    });
  };

  const updateHistory = (path, data, replace) => {
    const title = extract(data, "contentfulContentPage.title");
    LocationHelper.updateHistory(path, title, replace);
    EventAggregator.publish("trackPageview", { virtualPagePath: path, virtualPageTitle: title });
  };

  const renderPageInOverlay = (data, to) => {
    return new Promise((resolve, reject) => {
      if (!data) {
        reject("Data for page is null/undefined");
      }

      const location = {
        state: locationState,
      };

      const activeOverlay =
        globalContext.overlay.activeOverlays instanceof Array && globalContext.overlay.activeOverlays.length > 0
          ? globalContext.overlay.activeOverlays[0]
          : null;

      switch (activeOverlay) {
        default:
        case null: {
          globalContext.setValue(
            {
              overlay: {
                ...globalContext.overlay,
                activeOverlays: [FIRST],
                first: {
                  to: to,
                  isOpen: true,
                  props: { data, location },
                  animateCloseOverlay: false,
                },
              },
            },
            () => {
              const bothOpen = globalContext.overlay.second.isOpen;
              resolve({ data: data, replace: bothOpen });
            }
          );
          break;
        }
        case FIRST: {
          const activeOverlays = [...globalContext.overlay.activeOverlays];
          activeOverlays.splice(0, 0, SECOND);
          if (activeOverlays.length > 2) {
            activeOverlays.pop();
          }

          globalContext.setValue(
            {
              overlay: {
                ...globalContext.overlay,
                activeOverlays: activeOverlays,
                second: {
                  to: to,
                  isOpen: true,
                  props: { data, location },
                  animateCloseOverlay: false,
                },
              },
            },
            () => {
              const bothOpen = globalContext.overlay.first.isOpen;
              resolve({ data: data, replace: bothOpen });
            }
          );
          break;
        }
        case SECOND: {
          const activeOverlays = [...globalContext.overlay.activeOverlays];
          activeOverlays.splice(0, 0, SECOND);
          if (activeOverlays.length > 2) {
            activeOverlays.pop();
          }

          globalContext.setValue(
            {
              overlay: {
                ...globalContext.overlay,
                activeOverlays: activeOverlays,
                first: {
                  to: to,
                  isOpen: true,
                  props: { data, location },
                  animateCloseOverlay: false,
                },
              },
            },
            () => {
              const bothOpen = globalContext.overlay.second.isOpen;
              resolve({ data: data, replace: bothOpen });
            }
          );
          break;
        }
      }

      //const id = extract(data, 'contentfulContentPage.id');

      // const isOpen = globalContext.showPageInOverlay;

      // globalContext.setValue({
      //     showPageInOverlay: true,
      //     pageInOverlayProps: { data, location },
      //     overlayKey: `overlay-${id}`
      // }, () => {
      //     console.log('globalContext updated.');
      //     resolve({ data: data, replace: isOpen });
      // });

      // if(globalContext.showPageInOverlay) {
      //     globalContext.setValue({
      //         animateClosePageInOverlay: true
      //     }, () => {
      //         globalContext.setValue({
      //             showPageInOverlay: true,
      //             pageInOverlayProps: { data, location }
      //         }, () => resolve(data));
      //     })
      // } else {
      //     globalContext.setValue({
      //         showPageInOverlay: true,
      //         pageInOverlayProps: { data, location }
      //     }, () => resolve(data));
      // }
    });
  };

  fetchPageData(to)
    .then((response) => {
      if (response && response.json) {
        return renderPageInOverlay(response.json.data, to);
      }
    })
    .then((response) => {
      updateHistory(to, response.data, response.replace);
    })
    .catch((err) => {
      EventAggregator.publish("trackException", {
        customMessage: "fetchPageData failed",
        exception: err,
      });
    });
};

export default openOverlay;
