class AppendToDomHelper {
  loadScriptTag(appendToRef, src = "", id = null) {
    if (!(appendToRef && appendToRef.current)) {
      return Promise.reject("Missing DOM reference");
    }

    return new Promise((resolve, reject) => {
      const scriptTag = document.createElement("script");
      scriptTag.type = "text/javascript";
      scriptTag.src = src;
      scriptTag.async = true;
      scriptTag.onload = resolve;
      scriptTag.onerror = reject;
      scriptTag.id = id;
      appendToRef.current.appendChild(scriptTag);
    });
  }

  loadLinkCSSTag(appendToRef, src = "") {
    if (!(appendToRef && appendToRef.current)) {
      return Promise.reject("Missing DOM reference");
    }

    return new Promise((resolve, reject) => {
      const linkTag = document.createElement("link");
      linkTag.rel = "stylesheet";
      linkTag.type = "text/css";
      linkTag.href = src;
      linkTag.onload = resolve;
      linkTag.onerror = reject;
      appendToRef.current.appendChild(linkTag);
    });
  }
}

const helper = new AppendToDomHelper();
export default helper;
