class ImageFinderHelper {
  getImageFromBlocks(blocks, siteConfiguration) {
    let url = "";
    if (blocks instanceof Array && blocks.length) {
      for (let i = 0, iMax = blocks.length; i < iMax; i++) {
        switch (blocks[i].__typename) {
          case "ContentfulHeroBlock": {
            url = this.getHeroBlockImage(blocks[i]);
            break;
          }
          case "ContentfulHeaderBlock": {
            url = this.getHeaderBlockImage(blocks[i]);
            break;
          }
          default:
            url = "";
        }

        if (url) {
          break;
        }
      }
    }

    return url ? url : this.getFallBackImage(siteConfiguration);
  }

  getFallBackImage(siteConfiguration) {
    return siteConfiguration &&
      siteConfiguration.facebookFallbackImage &&
      siteConfiguration.facebookFallbackImage.file &&
      siteConfiguration.facebookFallbackImage.file.url
      ? siteConfiguration.facebookFallbackImage.file.url
      : "";
  }

  getHeroBlockImage(block) {
    return block && block.heroImageLargeDevice && block.heroImageLargeDevice.file && block.heroImageLargeDevice.file.url
      ? block.heroImageLargeDevice.file.url
      : "";
  }

  getHeaderBlockImage(block) {
    return block && block.imageForLargeDevice && block.imageForLargeDevice.file && block.imageForLargeDevice.file.url
      ? block.imageForLargeDevice.file.url
      : "";
  }
}

const helper = new ImageFinderHelper();
export default helper;
