class ThemeHelper {
  setTheme(theme) {
    this.theme = theme;
  }

  getTheme() {
    return this.theme;
  }
}

const singelton = new ThemeHelper();
export default singelton;
