class SiteSettingsTextHelper {
  getSiteSettingsTextByKey(siteSettings, keyValue) {
    if (!this.isValidParams(siteSettings, keyValue)) {
      return "";
    }

    let foundSiteText = false;

    if (this.textGroupExists(siteSettings)) {
      siteSettings.siteTexts.textGroups.find((group) => {
        if (this.textGroupHasTexts(group)) {
          return group.texts.list.find((item) => {
            if (item.key && item.key.toLowerCase() === keyValue.toLowerCase()) {
              foundSiteText = item;
            }

            return foundSiteText;
          });
        } else {
          return foundSiteText;
        }
      });
    }

    return foundSiteText && foundSiteText.value ? foundSiteText.value : "";
  }

  isValidParams(siteSettings, keyValue) {
    return siteSettings instanceof Object && keyValue ? true : false;
  }

  textGroupExists(siteSettings) {
    return siteSettings.siteTexts.textGroups instanceof Array && siteSettings.siteTexts.textGroups.length
      ? true
      : false;
  }

  textGroupHasTexts(group) {
    return group && group.texts && group.texts.list instanceof Array && group.texts.list.length ? true : false;
  }
}

const singleton = new SiteSettingsTextHelper();
module.exports = singleton;
