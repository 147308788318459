class BrowserSupportHelper {
  constructor() {
    if (typeof window !== "undefined") {
      this.isSupportingInputTypeDate = this.checkInputTypeDateSupport();
      this.isBrowserIE = this.checkIsBrowserIE();
    }
  }

  checkInputTypeDateSupport() {
    if (typeof window === "undefined") {
      return false;
    }

    var input = document.createElement("input");
    input.setAttribute("type", "date");

    var notADateValue = "not-a-date";
    input.setAttribute("value", notADateValue);

    const result = input.value !== notADateValue;
    return result;
  }

  checkIsBrowserIE() {
    if (typeof window === "undefined") {
      return false;
    }

    var ua = window.navigator.userAgent; //Check the userAgent property of the window.navigator object
    var msie = ua.indexOf("MSIE "); // IE 10 or older
    var trident = ua.indexOf("Trident/"); //IE 11

    const isIe = msie > 0 || trident > 0;
    return isIe;
  }

  checkIsBrowserEdge() {
    if (typeof window === "undefined") {
      return false;
    }

    return window.navigator.userAgent.indexOf("Edge") > -1;
  }
}

const helper = new BrowserSupportHelper();
export default helper;
